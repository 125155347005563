import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { socketConnection } from './api';

class App extends Component {
  constructor(props) {
    super(props);
    socketConnection((err, retVal) => {

      if (retVal.command === 'progress') {
        this.setState({
          downloadedSize: retVal.downloadedSize,
          size: retVal.size,
        })
      } else if (retVal.command === 'filename') {
        this.setState({ 
          filename: retVal.filename 
        })
      }
    });
  }

  state = {
    filename: 'no filename yet',
    size: null,
    downloadedSize: null,
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1 className="App-title">Share Link App</h1>
        </header>
        <p className="App-intro">
          Downloading file named: {this.state.filename}
          <br/>
          {this.state.size && <div>
            {this.state.downloadedSize} / {this.state.size} - {this.state.downloadedSize / this.state.size * 100}%
          </div>}
        </p>
      </div>
    );
  }
}

export default App;
