import openSocket from 'socket.io-client';
import Peer from 'simple-peer';

// const socket = openSocket('http://localhost:8000');
const socket = openSocket('https://slinkme.herokuapp.com');

const webRTCConfig = {"iceServers":[{"url":"stun:w3.xirsys.com"},{"username":"53076a66-d87b-11e8-b321-b741063f92ea","url":"turn:w3.xirsys.com:80?transport=udp","credential":"53076ade-d87b-11e8-bad3-6940b2c73c26"},{"username":"53076a66-d87b-11e8-b321-b741063f92ea","url":"turn:w3.xirsys.com:3478?transport=udp","credential":"53076ade-d87b-11e8-bad3-6940b2c73c26"},{"username":"53076a66-d87b-11e8-b321-b741063f92ea","url":"turn:w3.xirsys.com:80?transport=tcp","credential":"53076ade-d87b-11e8-bad3-6940b2c73c26"},{"username":"53076a66-d87b-11e8-b321-b741063f92ea","url":"turn:w3.xirsys.com:3478?transport=tcp","credential":"53076ade-d87b-11e8-bad3-6940b2c73c26"},{"username":"53076a66-d87b-11e8-b321-b741063f92ea","url":"turns:w3.xirsys.com:443?transport=tcp","credential":"53076ade-d87b-11e8-bad3-6940b2c73c26"},{"username":"53076a66-d87b-11e8-b321-b741063f92ea","url":"turns:w3.xirsys.com:5349?transport=tcp","credential":"53076ade-d87b-11e8-bad3-6940b2c73c26"}]}

function getFileUniqueId() {
  // Get the url path 
  const path = window.location.pathname

  if (path.length > 1) {
    return path.slice(1)
  }
  return null
}


function socketConnection(cb) {

  const path = getFileUniqueId()
  if (path) {
    let p

    socket.emit('getFileMeta', path, (resp) => {

      if (resp.error){
        console.log(resp.error)
        return
      }

      cb(null, {command: 'filename', filename: resp.fileName})

      const filePath = resp.fileName[0]
      console.log(filePath)
      const fileName = filePath.replace(/^.*[\\/]/, '')

      p = new Peer({initiator: true, trickle: false, config: webRTCConfig})

      // Give signaling data to the other peer
      p.on('signal', (signal) => {
        console.log('Signaling out to host')
        socket.emit('signalFromClient', {
          path, 
          signal: JSON.stringify(signal)
        })
      })

      socket.on('signal', (signal) => {
        console.log('Recieved Signaling Data')
        p.signal(JSON.parse(signal))
      })

      // Got data from the other peer!
      let collectedData = []
      let size = null
      let downloadedSize = 0
      let state = null

      // Called with each data chunk
      const addData = (data) => {
        downloadedSize += data.length
        collectedData.push(data)
        cb(null, {
          command: 'progress', 
          downloadedSize,
          size,
        })
      }

      p.on('data', (data) => {

        if (state === 'startFile' && downloadedSize < size) {
          addData(data)
        } else {
          try {
            const attemptedParse = JSON.parse(data)
            if (attemptedParse && attemptedParse.finished) {
              downloadFile(collectedData)
            } else if (attemptedParse && attemptedParse.cmd === 'startFile') {
              console.log('File Started')
              size = attemptedParse.size
              state = attemptedParse.cmd
            } else {
              // addData(data)
            }
          } catch (error) {
            // addData(data)
          }
        }

        // if (typeof data === 'string' || data instanceof String) {
        //   console.log("WOOOOO" + data);
        // } else {
        //   console.log("Got data packet")
        //   console.log(Object.prototype.toString.call(data))
        // }
        
      })

      const downloadFile = (allData) => {

        var file;
        // var data = chunk;
        var properties = {type: 'text/plain'}; // Specify the file's mime-type.
        try {
          // Specify the filename using the File constructor, but ...
          file = new File(allData, fileName, properties);
        } catch (e) {
          console.log("falling back to blob")
          // ... fall back to the Blob constructor if that isn't supported.
          file = new Blob(allData, properties);
        }
        var url = URL.createObjectURL(file);
        var dlLink = document.createElement('a')
        dlLink.href = url;
        dlLink.download = fileName;
        dlLink.click();
      }


    });
  }
}


export { socketConnection };